
import { defineComponent } from "vue-demi";
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "CommonQuestionnaireActions",
  emits: ["create", "createOnePage"],
  props: [
    "loading",
    "pending",
    "pendingStudent",
    "pendingOnePage",
    "questionnaire_id",
  ],
  methods: {
    modal(id: string, data: dynamicsObject) {
      this.$store.commit("createModal", { id, data });
    },
  },
});
